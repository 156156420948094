<template>
  <!-- <div></div> -->
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form layout="inline">
        <a-form-item label="">
          <a-range-picker v-model:value="time" :show-time="{ format: 'HH:mm', defaultValue: defaultValue }"
            format="YYYY-MM-DD HH:mm:ss" :placeholder="['收款开始日期', '收款结束日期']" @change="timeChange" allowClear />
        </a-form-item>
        <!-- <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.driverType" :options="$store.state.enumAll.financeTypeEnum"
            style="width: 183px" placeholder="账单类型">
          </a-select>
        </a-form-item> -->
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.orderStatus" style="width: 183px" placeholder="收款交易状态">
            <a-select-option :value="1">未支付</a-select-option>
            <a-select-option :value="4">交易成功</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.payMethod" :options="$store.state.enumAll.payMethodEnum"
            style="width: 183px" placeholder="生成方式">
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="账单号"/>
        </a-form-item> -->
        <a-form-item label="">
          <a-input v-model:value="searchForm.bizOrderNo" placeholder="流水号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.orderNo" placeholder="通联号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.businessId" placeholder="订单/账单号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.subject" placeholder="收款来源" allowClear />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSearch" :loading="loading">查询</a-button>
        </a-form-item>
        <a-form-item>
          <a-button @click="onResetting" :loading="loading">重置</a-button>
        </a-form-item>
        <a-form-item>
          <a-button @click="exportData" :disabled="disabled">导出</a-button>
        </a-form-item>
      </a-form>
    </template>
    <template #toolbarLeft>
      通联在途总金额：<span style="color: red">{{ amtData.estCollAmount || 0 }} </span>元
      &emsp; &emsp;
      头寸：<span style="color: red">{{ amtData.reserveBalance || 0 }} </span>元
      &emsp; &emsp;
      系统可用余额：<span style="color: red">{{ amtData.availSplitAmt || 0 }}元</span>
      &emsp; &emsp;
      收款总金额：<span style="color: red">{{ collectAmt }}元</span>
    </template>
    <a-table :columns="columns" :pagination="pagination" bordered :row-key="record => record.bizOrderNo"
      :data-source="listData" :loading="loading" @change="handleTableChange">
    </a-table>
  </suy-table>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import SuyTable from '@/components/SuyTable'
import moment from 'moment'
import { collectionPage, getPayeeFundsInTransitBalance, queryReserveFundBalance, collectExport, getCollectionAmt, getQueryBalance } from '@/api/yst/yst'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      loading: false,
      disabled: false,
      totalAmt: 0,
      balance: 0,
      available: 0,
      collectAmt: 0,
      amtData: {},
      time: [],
      searchForm: {
        payDatetimeStart: null,
        payDatetimeEnd: null,
        // driverType: null,
        orderStatus: 4,
        bizOrderNo: null,
        orderNo: null
      },
      defaultValue: [moment().hours(15).minutes(0).seconds(0), moment().hours(15).minutes(0).seconds(0)],
      mirrorSearchForm: {},
      listData: [],
      columns: [
        {
          title: '交易流水号',
          dataIndex: 'bizOrderNo',
          width: '250px'
        },
        {
          title: '通联号',
          dataIndex: 'orderNo'
        },
        {
          title: '订单/账单号',
          dataIndex: 'businessId'
        },
        {
          title: '收款码生成时间',
          dataIndex: 'createTime'
        },
        {
          title: '生成人',
          dataIndex: 'creator'
        },
        {
          title: '收款金额',
          dataIndex: 'amount'
        },
        {
          title: '支付状态',
          dataIndex: 'orderStatus.label'
        },
        {
          title: '可付余额',
          dataIndex: 'availSplitAmt'
        },
        {
          title: '内部分账余额',
          dataIndex: 'internalSplitAmt'
        },
        {
          title: '收款时间',
          dataIndex: 'payDatetime'
        },
        {
          title: '收款来源',
          dataIndex: 'subject'
        },
        {
          title: '生成方式',
          dataIndex: 'payMethod.label'
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const getBalance = () => {
      queryReserveFundBalance().then(res => {
        if (res.code === 10000) {
          state.balance = res.data.reserveBalance
        }
      })
    }
    const getCollectionAmtData = () => {
      getCollectionAmt({
        ...state.searchForm,
      }).then(res => {
        state.collectAmt = res.data
      })
    }
    const getQueryBalanceData = () => {
      getQueryBalance({
        ...state.searchForm,
      }).then(res => {
        state.amtData = res.data
      })
    }
    const loadData = () => {
      state.loading = true
      // getBalance()
      // onTotalAmt()
      getCollectionAmtData()
      getQueryBalanceData()
      collectionPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const onResetting = () => {
      // state.searchForm = {}
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.time = []
      onSearch()
    }
    const onTotalAmt = () => {
      getPayeeFundsInTransitBalance({}).then(res => {
        if (res.code === 10000) {
          state.totalAmt = res.data.estCollAmount
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
      })
    }
    const timeChange = (e, v) => {
      state.searchForm.payDatetimeStart = v[0]
      state.searchForm.payDatetimeEnd = v[1]
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const exportData = () => {
      state.disabled = true
      collectExport({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }, '财务-收款明细').then(res => {

      }).finally(() => { state.disabled = false })
    }
    return {
      ...toRefs(state),
      loadData,
      onTotalAmt,
      onSearch,
      timeChange,
      exportData,
      handleTableChange,
      onResetting
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
